import { unref as _unref, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

const _withScopeId = n => (_pushScopeId("data-v-65649258"), n = n(), _popScopeId(), n);

const _hoisted_1 = {
  class: "topbutton"
};
import { reactive, ref } from 'vue';
import { Search, Refresh } from '@element-plus/icons-vue';
import modaldetail from './modal-detail.vue'; // 新增/修改

export default {
  __name: 'AdministratorLog',

  setup(__props) {
    const tableData = [{
      id: 1,
      pid: 0,
      name: 'admin',
      title: '登录',
      url: '/admin/index/login',
      Ip: '112.111.111.111',
      userAgent: 'Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/123.0.0.0 Safari/537.36',
      create_time: '2024-08-07 16:24:02',
      ID: '111'
    }, {
      id: 2,
      pid: 0,
      name: 'admin2',
      title: '登录',
      url: '/admin/index/login222',
      Ip: '112.111.111.111',
      userAgent: 'Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/123.0.0.0 Safari/537.36',
      create_time: '2024-08-07 16:24:02',
      ID: '222'
    }, {
      id: 3,
      pid: 0,
      name: 'admin3',
      title: '登录',
      url: '/admin/index/login333',
      Ip: '112.111.111.111',
      userAgent: 'Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/123.0.0.0 Safari/537.36',
      create_time: '2024-08-07 16:24:02',
      ID: '333'
    }]; // 打开详情弹窗

    const modaldetailRef = ref();

    const openmodaldetailRef = record => {
      console.log(record);
      modaldetailRef.value.showModal(record);
    };

    const pagination = reactive({
      page_num: 1,
      page_size: 10,
      total: 0
    });
    return (_ctx, _cache) => {
      const _component_el_button = _resolveComponent("el-button");

      const _component_el_tooltip = _resolveComponent("el-tooltip");

      const _component_el_table_column = _resolveComponent("el-table-column");

      const _component_el_tag = _resolveComponent("el-tag");

      const _component_el_table = _resolveComponent("el-table");

      const _component_el_pagination = _resolveComponent("el-pagination");

      const _component_el_row = _resolveComponent("el-row");

      return _openBlock(), _createElementBlock(_Fragment, null, [_createElementVNode("div", null, [_createElementVNode("div", _hoisted_1, [_createVNode(_component_el_tooltip, {
        content: "刷新",
        placement: "top"
      }, {
        default: _withCtx(() => [_createVNode(_component_el_button, {
          color: "#626aef",
          icon: _unref(Refresh)
        }, null, 8, ["icon"])]),
        _: 1
      })]), _createVNode(_component_el_table, {
        data: tableData,
        style: {
          "width": "100%",
          "margin-bottom": "20px"
        },
        "row-key": "id",
        border: ""
      }, {
        default: _withCtx(() => [_createVNode(_component_el_table_column, {
          type: "selection",
          width: "50"
        }), _createVNode(_component_el_table_column, {
          label: "ID",
          prop: "pid"
        }), _createVNode(_component_el_table_column, {
          label: "管理ID",
          prop: "ID"
        }), _createVNode(_component_el_table_column, {
          label: "管理用户名",
          prop: "name"
        }), _createVNode(_component_el_table_column, {
          label: "标题",
          prop: "title"
        }), _createVNode(_component_el_table_column, {
          label: "URL",
          prop: "url"
        }), _createVNode(_component_el_table_column, {
          label: "IP"
        }, {
          default: _withCtx(scoped => [_createVNode(_component_el_tag, {
            type: "primary"
          }, {
            default: _withCtx(() => [_createTextVNode(_toDisplayString(scoped.row.Ip), 1)]),
            _: 2
          }, 1024)]),
          _: 1
        }), _createVNode(_component_el_table_column, {
          label: "userAgent",
          width: "400"
        }, {
          default: _withCtx(scoped => [_createVNode(_component_el_tooltip, {
            content: scoped.row.userAgent,
            placement: "top"
          }, {
            default: _withCtx(() => [_createTextVNode(_toDisplayString(scoped.row.userAgent), 1)]),
            _: 2
          }, 1032, ["content"])]),
          _: 1
        }), _createVNode(_component_el_table_column, {
          label: "创建时间",
          prop: "create_time"
        }), _createVNode(_component_el_table_column, {
          label: "操作"
        }, {
          default: _withCtx(scoped => [_createVNode(_component_el_tooltip, {
            content: "查看详情",
            placement: "top"
          }, {
            default: _withCtx(() => [_createVNode(_component_el_button, {
              color: "#626aef",
              icon: _unref(Search),
              onClick: $event => openmodaldetailRef(scoped.row)
            }, null, 8, ["icon", "onClick"])]),
            _: 2
          }, 1024)]),
          _: 1
        })]),
        _: 1
      }), _createVNode(_component_el_row, {
        justify: "end",
        class: "mt-20"
      }, {
        default: _withCtx(() => [_createVNode(_component_el_pagination, {
          onCurrentChange: _ctx.handleCurrentChange,
          "page-size": pagination.page_size,
          modelValue: pagination.page_num,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => pagination.page_num = $event),
          background: "",
          layout: "total,prev, pager, next",
          total: pagination.total
        }, null, 8, ["onCurrentChange", "page-size", "modelValue", "total"])]),
        _: 1
      })]), _createVNode(modaldetail, {
        ref_key: "modaldetailRef",
        ref: modaldetailRef
      }, null, 512)], 64);
    };
  }

};